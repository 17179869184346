import Statsig, { StatsigUser } from "statsig-node";
import type { ReadonlyHeaders } from "next/dist/server/web/spec-extension/adapters/headers";
import { PrivateConfig } from "../PrivateConfig";
import { PublicConfig } from "../PublicConfig";
import { PageSearchParams } from "../types/AppRouter";
import { nanoid } from "nanoid";
import { UIUtils } from "./UIUtils";
import { LogUtils } from "./LogUtils";
import { Quote } from "../types/Quote.interface";

const initializeStatsig = PrivateConfig.IS_STATSIG_ENABLED
    ? Statsig.initialize(PrivateConfig.STATSIG_SERVER_KEY, { environment: { tier: PublicConfig.ENVIRONMENT } })
    : Promise.resolve(false);

export class StatsigUtils {
    static async getStatsigValues(user: StatsigUser): Promise<string | null> {
        try {
            const isStatsigReady = await initializeStatsig;
            if (isStatsigReady === false) {
                LogUtils.devLog(`Statsig initialization: SKIPPED`);
                return null;
            }
            LogUtils.devLog(`Statsig initialization: SUCCESS`);
        } catch (err) {
            LogUtils.devLog(`Statsig initialization: FAILED`, "error");
        }

        const values = Statsig.getClientInitializeResponse(user, PublicConfig.STATSIG_CLIENT_KEY, {
            hash: "djb2"
        });

        return JSON.stringify(values);
    }

    static getStatsigUserProperties({ userID, headers, searchParams }: { userID: string; headers: ReadonlyHeaders; searchParams: PageSearchParams }): StatsigUser {
        const ipAddress = headers.get("x-forwarded-for") ?? undefined;
        const userAgent = headers.get("user-agent") ?? undefined;

        const custom: Record<string, string> = {};
        Object.entries(searchParams).forEach(([key, value]) => {
            if (value !== undefined) {
                if (Array.isArray(value)) {
                    const lastValue = value[value.length - 1];
                    if (lastValue !== undefined) {
                        custom[`query_${key}`] = lastValue;
                    }
                } else {
                    custom[`query_${key}`] = value;
                }
            }
        });

        return { userID, ip: ipAddress, userAgent, custom };
    }

    static getStatsigQuoteAttributes({ quote }: { quote?: Quote }) {
        const quoteHasDogs = !!quote?.policies?.some(policy => policy?.species === "Dog");
        const quoteHasCats = !!quote?.policies?.some(policy => policy?.species === "Cat");
        const quotePetCount = quote?.policies?.length ?? 0;

        return {
            quoteHasDogs: quoteHasDogs,
            quoteHasCats: quoteHasCats,
            quotePetCount: quotePetCount
        };
    }

    static getStatsigUser({
        searchParams,
        headers,
        userID,
        initialCustom
    }: {
        searchParams: PageSearchParams;
        headers: ReadonlyHeaders;
        userID?: string;
        initialCustom?: StatsigUser["custom"];
    }): StatsigUser {
        const testUserIdFromParams = UIUtils.getCaseInsensitiveValue(searchParams, "tuid");
        const testUserIdFromHeader = headers.get("x-tuid");
        const tuidCreated = headers.get("x-tuid-created") === "true";

        let finalUserID: string;
        let isNewTUID: boolean;

        if (testUserIdFromParams) {
            finalUserID = testUserIdFromParams;
            isNewTUID = false;
        } else if (userID) {
            finalUserID = userID;
            isNewTUID = false;
        } else if (testUserIdFromHeader) {
            finalUserID = testUserIdFromHeader;
            isNewTUID = tuidCreated;
        } else {
            finalUserID = nanoid();
            isNewTUID = true;
        }

        const userProperties = this.getStatsigUserProperties({ userID: finalUserID, headers, searchParams });

        return {
            ...userProperties,
            custom: {
                ...userProperties.custom,
                ...initialCustom,
                isNewTUID
            }
        };
    }
}

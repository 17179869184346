import { useEffect, useRef } from "react";
import { useStatsigUser, useStatsigClient } from "@statsig/react-bindings";
import { StatsigUser } from "@statsig/js-client";
import { Quote } from "../types/Quote.interface";
import { StatsigUtils } from "../utils/StatsigUtils";

interface UseStatsigUserUpdate {
    user?: StatsigUser;
    quote?: Quote;
}

export const useStatsigUserUpdate = ({ quote }: UseStatsigUserUpdate) => {
    const userUpdatingRef = useRef(false);
    const { policies } = quote || {};
    const { client } = useStatsigClient();
    const { user } = useStatsigUser();
    const { quoteHasCats, quoteHasDogs, quotePetCount } = StatsigUtils.getStatsigQuoteAttributes({ quote });

    useEffect(() => {
        if (!user || !policies?.length) return;

        const updateUser = async (nextUser: StatsigUser) => {
            if (!client) {
                console.log(`No Statsig client found`);
                return;
            }
            try {
                await client.updateUserAsync(nextUser);
                userUpdatingRef.current = false;
            } catch (err) {
                console.error(`Failed to update Statsig user: ${err}`);
                return;
            }
        };

        const needsUpdate = user.custom?.quoteHasDogs !== quoteHasDogs || user.custom?.quoteHasCats !== quoteHasCats || user.custom?.quotePetCount !== quotePetCount;

        if (needsUpdate && !userUpdatingRef.current) {
            userUpdatingRef.current = true;
            const updatedUser = { ...user, custom: { ...user.custom, quoteHasDogs, quoteHasCats, quotePetCount } };
            updateUser(updatedUser);
        }
    }, [client, policies?.length, quoteHasCats, quoteHasDogs, quotePetCount, user]);
};

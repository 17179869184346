import { useState, useRef, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useRouter, useSearchParams } from "next/navigation";

import { useAppLayerContext } from "@/shared/contexts/AppLayer";

import { CookieUtils } from "@/shared/utils/CookieUtils";
import { UIUtils } from "@/shared/utils/UIUtils";
import { PublicConfig } from "@/shared/PublicConfig";
import { Quote } from "@/shared/types/Quote.interface";

type UseQuoteStatusProps = {
    quote?: Quote;
    isSubmitting: boolean;
    cookieQuoteId: string;
    cookieFinalizeStarted: string;
    underwriter: string;
    formId: string;
};

export const useQuoteStatus = ({ quote, isSubmitting, underwriter, cookieQuoteId, cookieFinalizeStarted, formId }: UseQuoteStatusProps) => {
    const [shouldPollStatus, setShouldPollStatus] = useState(false);
    const [hasTimedOut, setHasTimedOut] = useState(false);
    const pollRef = useRef<ReturnType<typeof setInterval> | null>(null);
    const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    const queryClient = useQueryClient();
    const { updateAppState } = useAppLayerContext();
    const router = useRouter();
    const searchParams = useSearchParams();

    const { quoteStatus } = quote || {};

    const isFinalizeCookieSet = CookieUtils.exists(cookieFinalizeStarted);
    const isStatusOpenWithFinalizeCookie = quoteStatus === "open" && isFinalizeCookieSet;

    useEffect(() => {
        if (quoteStatus === "failed-authorization") {
            updateAppState({ isQuoteUpdating: false, showFinalLoader: false });
        }
    }, [quoteStatus, updateAppState]);

    useEffect(() => {
        const scrollToTop = async () => {
            await UIUtils.scrollToTop(3000, 50);
        };

        // Don't start polling if we've already timed out
        if (hasTimedOut) {
            return;
        }

        if ((quoteStatus === "finalize-processing" || isStatusOpenWithFinalizeCookie) && !pollRef.current) {
            updateAppState({ isQuoteUpdating: true, showFinalLoader: true });
            setShouldPollStatus(true);
            scrollToTop();

            pollRef.current = setInterval(() => {
                queryClient.invalidateQueries({ queryKey: ["quote"] });
            }, PublicConfig.PTZ_US.FINALIZE_POLLING_INTERVAL);

            timeoutRef.current = setTimeout(() => {
                if (pollRef.current) {
                    clearInterval(pollRef.current);
                    pollRef.current = null;
                }
                setShouldPollStatus(false);
                setHasTimedOut(true);

                if (isStatusOpenWithFinalizeCookie) {
                    CookieUtils.remove(cookieFinalizeStarted);
                    window.location.reload();
                } else {
                    updateAppState({
                        isQuoteUpdating: false,
                        showFinalLoader: false,
                        isEnrollmentTimeoutModalVisible: true
                    });
                }
            }, PublicConfig.PTZ_US.FINALIZE_MAX_TIMEOUT);
        }

        if (shouldPollStatus && pollRef.current && quoteStatus !== "finalize-processing" && !isStatusOpenWithFinalizeCookie) {
            setShouldPollStatus(false);
            clearInterval(pollRef.current);
            pollRef.current = null;

            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
                timeoutRef.current = null;
            }
            updateAppState({ isQuoteUpdating: false, showFinalLoader: false });
        }

        return () => {
            if (pollRef.current) {
                clearInterval(pollRef.current);
                pollRef.current = null;
            }
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
                timeoutRef.current = null;
            }
        };
    }, [cookieFinalizeStarted, hasTimedOut, isStatusOpenWithFinalizeCookie, queryClient, quoteStatus, shouldPollStatus, updateAppState]);

    useEffect(() => {
        const scrollToTop = async () => {
            await UIUtils.scrollToTop(3000, 50);
        };

        if (quoteStatus === "finalized" && !isSubmitting) {
            scrollToTop();
            const params = new URLSearchParams(searchParams.toString());
            const _quoteIdInParams = UIUtils.getCaseInsensitiveValue(params, "quoteId");
            const _underwriter = UIUtils.getCaseInsensitiveValue(params, "uw");
            const _quoteId = quote?.id;

            if (_quoteId && !_quoteIdInParams) {
                params.set("quoteId", _quoteId);
            }

            if (!_underwriter) {
                params.set("uw", underwriter);
            }
            CookieUtils.remove(cookieQuoteId);
            const newUrl = UIUtils.toUrlString(`${PublicConfig.BASE_PATH}/forms/${formId}/thankyou`, params);
            router.push(newUrl);
        }
    }, [cookieQuoteId, formId, isSubmitting, quote?.id, quoteStatus, router, searchParams, underwriter]);
};

// components
import { Card, Heading } from "@/shared/components/ui";
import PolicySummaryContent from "./PolicySummaryContent";
import PolicySummaryFooter from "./PolicySummaryFooter";

// utils
import { cn } from "@/shared/utils";
import { CoverageUtils } from "../../utils/CoverageUtils";

// types
import { PetBreed } from "spot-types/entities/PetBreed";
import { Policy, Quote } from "@/shared/types/Quote.interface";
import { useStatsig } from "@/shared/hooks/useStatsig";
import { PublicConfig } from "@/shared/PublicConfig";
import { AnalyticsUtils } from "@/shared/utils/AnalyticsUtils";
import DetailRow from "../DetailRow";

export interface PolicySummaryConfig {
    // Show accordion toggle button. Defaults to true. If false, accordion items will always be open.
    enableAccordionToggle?: boolean;
    // Show pre-discounted total price. Defaults to true. If false, only show the final discounted price (ex: on Thank You page).
    showPreDiscountPrice?: boolean;
    // Defaults to false. If true, will use generic discount label instead of specific discount label. Needed when discount label is not provided by API.
    useGenericDiscountLabel?: boolean;
    // Defaults to false. If true, will show the policy summary in a single column layout.
    isSingleCol?: boolean;
}

interface PolicySummaryProps {
    quote: Quote;
    breeds: PetBreed[] | undefined;
    config?: PolicySummaryConfig;
    wrapperClass?: string;
}

const PolicySummary = (props: PolicySummaryProps) => {
    const { quote, breeds, wrapperClass, config } = props;
    const policies = quote?.policies ?? [];
    const transactionFee = quote?.transactionFee?.value ?? 0;
    const totalPrice = CoverageUtils.calculatePolicyTotal(policies, transactionFee);
    const billingFrequency = quote?.billingInfo?.frequency ?? "monthly";
    const isAnnualBilling = billingFrequency === "yearly";
    const queryParams = AnalyticsUtils.getQueryParams(quote?.extra?.queryParams);

    // Statsig:start
    const { summaryStyleVariant } = useStatsig({
        layerId: PublicConfig.STATSIG_LAYER_ID_MAIN,
        layerParameters: [{ key: "summaryStyleVariant", defaultValue: "default" }] as const,
        queryParams
    });
    // Statsig:end

    const cardStyleClass = summaryStyleVariant === "default" ? "border-0 bg-background-brand-b-secondary rounded-xl" : "border bg-brand-weakest rounded-b-xl rounded-t-none";
    const textStyles = `${summaryStyleVariant === "default" ? "text-xs" : "text-sm"} leading-5 text-content-primary`;

    const renderImportantDatesSummary = (policies: Policy[]) => {
        let accidentCoverageDate = "";
        let illnessCoverageDate = "";
        let wellnessCoverageDate = "";

        // Find the date for each coverage type
        policies.forEach(policy => {
            const coverages = policy.coverageSettings?.coverages || [];

            coverages.forEach(coverage => {
                if (coverage.type === "accident" && coverage.startDate) {
                    accidentCoverageDate = coverage.startDate;
                } else if (coverage.type === "illness" && coverage.startDate) {
                    illnessCoverageDate = coverage.startDate;
                } else if ((coverage.type === "wellness-gold" || coverage.type === "wellness-platinum") && coverage.startDate) {
                    wellnessCoverageDate = coverage.startDate;
                }
            });
        });

        const accidentCoverageLabel =
            summaryStyleVariant === "billing-rearrange-2000" ? (
                "Accident Coverage Starts"
            ) : (
                <>
                    Accident coverage starts{" "}
                    <b className="whitespace-nowrap">
                        {" "}
                        {CoverageUtils.calculateCoverageDate(accidentCoverageDate, 0, "relative") + " on " + CoverageUtils.calculateCoverageDate(accidentCoverageDate, 0, "long")}
                    </b>
                </>
            );
        const illnessCoverageLabel =
            summaryStyleVariant === "billing-rearrange-2000" ? (
                "Illness Coverage Starts"
            ) : (
                <>
                    Illness coverage starts{" "}
                    <b className="whitespace-nowrap">
                        {" "}
                        {CoverageUtils.calculateCoverageDate(illnessCoverageDate, 0, "relative") + " on " + CoverageUtils.calculateCoverageDate(illnessCoverageDate, 0, "long")}
                    </b>
                </>
            );
        const combinedCoverageLabel =
            summaryStyleVariant === "billing-rearrange-2000" ? (
                "Accident & Illness Coverage Starts"
            ) : (
                <>
                    Accident & illness coverage starts{" "}
                    <b className="whitespace-nowrap">
                        {" "}
                        {CoverageUtils.calculateCoverageDate(illnessCoverageDate, 0, "relative") + " on " + CoverageUtils.calculateCoverageDate(illnessCoverageDate, 0, "long")}
                    </b>
                </>
            );
        const wellnessCoverageLabel =
            summaryStyleVariant === "billing-rearrange-2000" ? (
                "Preventive Care Coverage Starts"
            ) : (
                <>
                    Preventive care coverage starts{" "}
                    <b className="whitespace-nowrap">
                        {" "}
                        {CoverageUtils.calculateCoverageDate(wellnessCoverageDate, 0, "relative") + ", " + CoverageUtils.calculateCoverageDate(wellnessCoverageDate, 0, "long")}
                    </b>
                </>
            );

        const rows = [];

        if (wellnessCoverageDate) {
            rows.push(
                <DetailRow
                    key="wellness"
                    label={wellnessCoverageLabel}
                    labelClass="text-content-primary"
                    value={summaryStyleVariant === "billing-rearrange-2000" && CoverageUtils.calculateCoverageDate(wellnessCoverageDate)}
                    valueClass="font-semibold"
                    wrapperClass={textStyles}
                />
            );
        }

        /* If Accident and Illness coverages have different dates */
        if (accidentCoverageDate && illnessCoverageDate && accidentCoverageDate !== illnessCoverageDate) {
            rows.push(
                <DetailRow
                    key="accident"
                    label={accidentCoverageLabel}
                    labelClass="text-content-primary"
                    value={summaryStyleVariant === "billing-rearrange-2000" && CoverageUtils.calculateCoverageDate(accidentCoverageDate)}
                    valueClass="font-semibold"
                    wrapperClass={textStyles}
                />
            );

            rows.push(
                <DetailRow
                    key="illness"
                    label={illnessCoverageLabel}
                    labelClass="text-content-primary"
                    value={summaryStyleVariant === "billing-rearrange-2000" && CoverageUtils.calculateCoverageDate(illnessCoverageDate)}
                    valueClass="font-semibold"
                    wrapperClass={textStyles}
                />
            );
        } /* If Accident and Illness coverages have same dates */ else if (accidentCoverageDate && illnessCoverageDate) {
            rows.push(
                <DetailRow
                    key="combined"
                    label={combinedCoverageLabel}
                    labelClass="text-content-primary"
                    value={summaryStyleVariant === "billing-rearrange-2000" && CoverageUtils.calculateCoverageDate(illnessCoverageDate)}
                    valueClass="font-semibold"
                    wrapperClass={textStyles}
                />
            );
        } /* If Accident only */ else if (accidentCoverageDate) {
            rows.push(
                <DetailRow
                    key="accidentOnly"
                    label={accidentCoverageLabel}
                    labelClass="text-content-primary"
                    value={summaryStyleVariant === "billing-rearrange-2000" && CoverageUtils.calculateCoverageDate(accidentCoverageDate)}
                    valueClass="font-semibold"
                    wrapperClass={textStyles}
                />
            );
        }

        return rows;
    };

    return (
        <div className={cn("relative flex w-full flex-col", wrapperClass)}>
            {summaryStyleVariant !== "default" && (
                <div className="flex w-full items-center justify-start gap-2 rounded-t-xl bg-background-primary-invert p-4">
                    <div className="font-poppins break-words text-lg font-bold leading-7 text-content-primary-invert">Plan Summary</div>
                </div>
            )}
            <div className="grow">
                <Card padding="default" className={cn(cardStyleClass)}>
                    <PolicySummaryContent quote={quote} breeds={breeds} config={config} isAnnualBilling={isAnnualBilling} summaryStyleVariant={summaryStyleVariant} />
                    {/* Important Dates */}
                    {summaryStyleVariant !== "default" && (
                        <div className="space-y-2 bg-background-brand-b-secondary p-4">
                            <Heading level="h4" className="text-base font-bold text-content-primary">
                                Important Dates
                            </Heading>

                            {renderImportantDatesSummary(policies)}
                        </div>
                    )}
                    <PolicySummaryFooter
                        policiesCount={policies.length}
                        transactionFee={transactionFee}
                        totalPrice={totalPrice}
                        billingFrequency={billingFrequency}
                        isSingleCol={config?.isSingleCol}
                        summaryStyleVariant={summaryStyleVariant}
                    />
                </Card>
            </div>
        </div>
    );
};

export default PolicySummary;

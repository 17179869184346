import React, { useEffect, useMemo, useState } from "react";
import { z } from "zod";
import { AnimatePresence, motion } from "framer-motion";
import { useFormContext, Controller } from "react-hook-form";

// utils
import { US_STATES } from "@/shared/utils/constants";
import { UIUtils } from "@/shared/utils/UIUtils";
import { PublicConfig } from "@/shared/PublicConfig";
import { cn } from "@/shared/utils";
import Strings from "@/shared/utils/Strings.constants";
import ErrorUtils from "@/shared/utils/ErrorUtils";
import { PtzUsDataUtils } from "../utils/PtzUsDataUtils";

// components
import Terms from "./Terms";
import StripeCheckout from "./StripeCheckout";
import PolicySummary from "@/shared/components/PolicySummary";
import { FormField } from "@/shared/components/FormField";
import { SelectWithLabel } from "@/shared/components/ui/SelectWithLabel";
import { Heading } from "@/shared/components/ui/Heading";
import { InputWithLabel } from "@/shared/components/ui/InputWithLabel";
import { RadioButtonGroup } from "@/shared/components/ui/RadioButtonGroup";

// hooks
import { useBreeds } from "@/shared/hooks/useBreeds";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";
import { useFormParentContext } from "@/shared/contexts/FormParent";
import { useStateFromZipcode } from "@/shared/hooks/useRatingAddress";

// media
import DollarIcon from "../../../../src/media/icons/dollars-icon.svg";
import { faCircleCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// types
import { ErrorIdType } from "@/shared/types/SpotAPI";
import { NonNullableKeys, ObjectPaths } from "@/shared/utils/TypeUtils";
import { FormStepProps, OtherProps } from "@/shared/types/Form";
import { Quote, ExtendedBillingStepSchema } from "@/shared/types/Quote.interface";
import { PRIORITY_CODE_DEPRECATED } from "@/shared/types/SpotAPI";

// Types for Form and Frequency
export type AllBillingStepProps = z.infer<typeof ExtendedBillingStepSchema>;
type StepKeys = Extract<keyof Quote, keyof AllBillingStepProps>;
type OtherKeys = Exclude<keyof AllBillingStepProps, keyof Quote>;
type FrequencyType = "monthly" | "yearly";
type AllBillingStepPaths = ObjectPaths<NonNullableKeys<AllBillingStepProps>>;

export function BillingEditor(props: FormStepProps<Quote, StepKeys, OtherProps | undefined>) {
    const { appState, updateAppState } = useAppLayerContext();
    const { asyncErrors, showCreditCardFields, priorityCode, mockAPIError, isQuoteUpdating, updateQuote } = appState;
    const quote = props?.value as Quote;
    const isStripeCheckoutEnabled = PublicConfig.PTZ_US.THIRD_PARTY_PAYMENT_PROCESSOR.toUpperCase() === "STRIPE";
    const fieldLabels = PtzUsDataUtils.getPetStepFieldLabels("floating");

    const {
        control,
        setValue,
        setError,
        setFocus,
        clearErrors,
        watch,
        getValues,
        formState: { errors, submitCount }
    } = useFormContext<AllBillingStepProps>();

    const { getValues: getFormParentValues } = useFormParentContext();

    const billingState = watch("billingInfo.address.state");
    const billingZipcode = watch("billingInfo.address.zipCode");

    // Billing Frequency
    const calculatedFrequency = appState?.billingFrequency;
    const currentFrequency = quote?.billingInfo?.frequency ?? "monthly";
    const transactionFee = quote.transactionFee?.value ?? 0;

    //Store the monthly fee on mount
    const [storedMonthlyFee, setStoredMonthlyFee] = useState(() => {
        return currentFrequency === "monthly" ? transactionFee : 0;
    });

    // Update the stored monthly fee when the transaction fee changes
    useEffect(() => {
        if (currentFrequency === "monthly") {
            setStoredMonthlyFee(transactionFee);
        }
    }, [currentFrequency, transactionFee]);

    const showTransactionFee = storedMonthlyFee > 0;

    const frequencyToDisplay = calculatedFrequency ?? currentFrequency;
    const [selectedBillingFrequency, setSelectedBillingFrequency] = useState<FrequencyType>(frequencyToDisplay);

    const { breeds } = useBreeds(PublicConfig.PTZ_US.UNDERWRITER);
    const { stateFromZip } = useStateFromZipcode(billingZipcode);

    const firstName = props.value?.firstName;
    const lastName = props.value?.lastName;

    const isPetParent = firstName?.toLowerCase() === "pet" && lastName?.toLowerCase() === "parent";

    const needsFirstLastName = !firstName || !lastName;

    const handleFrequencyChange = async (newValue: FrequencyType) => {
        if (isQuoteUpdating) return;

        // Update the form state for the frequency value
        setValue("billingInfo.frequency", newValue, { shouldTouch: true, shouldDirty: true });
        setSelectedBillingFrequency(newValue);

        const onUpdateQuote = async () => {
            const formValues = getFormParentValues();
            try {
                return await updateQuote?.mutateAsync({
                    ...formValues,
                    billingInfo: { frequency: newValue }
                });
            } catch (error) {
                const hasPcodeError = ErrorUtils.hasSpotError(error, PRIORITY_CODE_DEPRECATED);

                // If there's a pcode error, retry the update with a default pcode
                if (hasPcodeError) {
                    let retryData: Quote = { ...formValues, affiliateCode: "SPOT", discountCode: "SPOT" };
                    if (PublicConfig.ENVIRONMENT === "development" && mockAPIError) {
                        const updatedExtra = { ...retryData.extra, sim: "" };
                        retryData = { ...retryData, extra: updatedExtra };
                    }
                    return await updateQuote?.mutateAsync({
                        ...retryData,
                        billingInfo: { frequency: newValue }
                    });
                }

                console.error("Error updating quote frequency:", error);
                setSelectedBillingFrequency(currentFrequency);
                updateAppState({
                    billingFrequency: undefined,
                    isAnnualBilling: quote?.billingInfo?.frequency === "yearly"
                });
            }
        };

        // Update the quote asynchronously
        const updatedQuote = await onUpdateQuote();

        // Ensure the frequency is correctly set from the updated quote
        const frequencyInResponse = (updatedQuote?.billingInfo?.frequency ?? "monthly") as FrequencyType;

        setValue("billingInfo.frequency", frequencyInResponse, { shouldTouch: true, shouldDirty: true });
        setSelectedBillingFrequency(frequencyInResponse);
        // Update the app state with the updated frequency
        updateAppState({
            billingFrequency: undefined,
            isAnnualBilling: frequencyInResponse === "yearly"
        });
    };

    const handleExtraChange = async (updateValue: any) => {
        const currentValues = getFormParentValues();
        if (isQuoteUpdating) return;
        try {
            const currentExtra = currentValues?.extra;
            const mergedExtra = { ...currentExtra, ...updateValue };
            updateQuote?.mutate({ ...currentValues, extra: mergedExtra });
        } catch (error) {
            console.log(error);
        }
    };

    const allBillingErrorsMap: Partial<Record<ErrorIdType, { path?: AllBillingStepPaths; message: string; stripe?: boolean; header?: string }>> = useMemo(
        () => ({
            "invalid-billing-address": { path: "billingInfo.address.street1", message: "Invalid billing address" },
            "invalid-billing-name": { path: "billingInfo.nameOnCard", message: "Invalid billing name" },
            "invalid-credit-card-name": { path: "billingInfo.nameOnCard", message: "Invalid billing name" },
            "invalid-phone-number": { path: "phone", message: "Invalid phone number" },
            "invalid-postal-code": { path: "billingInfo.address.zipCode", message: Strings.ERRORS.ZIP },
            "invalid-credit-card-cvv": { stripe: true, message: "", header: Strings.ERRORS.CARD_CVV },
            "invalid-credit-card-declined": { stripe: true, message: "" },
            "invalid-credit-card-number": { stripe: true, message: "" },
            "priority-code-deprecated": { path: undefined, message: "" }
        }),
        []
    );

    useEffect(() => {
        if (!!asyncErrors && asyncErrors?.length > 0 && submitCount > 0) {
            clearErrors();

            let handledAllErrors = true;
            let asyncStripeError: any | undefined = undefined;

            asyncErrors.forEach(error => {
                const errorMapping = allBillingErrorsMap[error.id];
                if (!!errorMapping && !!errorMapping.path) {
                    setError(errorMapping.path, { message: errorMapping.message });
                    setFocus(errorMapping.path);
                } else if (!!errorMapping && errorMapping?.stripe) {
                    asyncStripeError = errorMapping;
                } else if (!!errorMapping && error.id === PRIORITY_CODE_DEPRECATED) {
                    // do nothing
                    // pcode error is retried in formController:onSubmit
                } else {
                    handledAllErrors = false;
                }
            });

            // Handle Stripe specific PaymentElement errors
            if (!!asyncStripeError?.stripe) {
                updateAppState({ stripePaymentElementError: asyncStripeError });
            }

            // If we encounter an error that can't be handled, we set a flag to show a generic error message
            if (!handledAllErrors) {
                updateAppState({ hasUnknownError: true });
            }
        }
    }, [allBillingErrorsMap, asyncErrors, clearErrors, setError, setFocus, submitCount, updateAppState]);

    // Changes State value based on Zip Code value
    useEffect(() => {
        if (!!stateFromZip?.abbreviation && billingState !== stateFromZip.abbreviation) {
            setValue(`billingInfo.address.state`, stateFromZip.abbreviation, { shouldTouch: true, shouldDirty: true });
        }
    }, [setValue, stateFromZip?.abbreviation, billingState]);

    // Clear any payment element errors when the user switches between payment methods
    useEffect(() => {
        if (!showCreditCardFields) {
            updateAppState({ stripePaymentElementError: undefined });
        }
    }, [showCreditCardFields, updateAppState]);

    return (
        <>
            <div className="flex flex-col gap-7">
                <PolicySummary quote={quote} breeds={breeds} wrapperClass="md:hidden" />
                <div className="grid md:grid-cols-2 md:gap-8">
                    <div className="flex flex-col gap-7">
                        {(isPetParent || needsFirstLastName) && (
                            <div className="mx-auto flex w-full flex-col gap-3">
                                <Heading level="h2" className="text-lg font-bold">
                                    Pet Parent Info
                                </Heading>
                                <div className={cn("flex flex-col gap-6")}>
                                    <Controller
                                        name="billingInfo.firstName"
                                        control={control}
                                        render={({ field: { ref, onChange, ...rest } }) => (
                                            <FormField className="min-w-[250px] flex-1" error={errors?.billingInfo?.firstName?.message} errorId="error-given-name">
                                                <InputWithLabel
                                                    variant="floating"
                                                    label={fieldLabels.firstName.label}
                                                    inputRef={ref}
                                                    error={errors?.billingInfo?.firstName?.message}
                                                    inputProps={{
                                                        ...rest,
                                                        autoComplete: "given-name",
                                                        onChange: e => {
                                                            if (errors?.billingInfo?.firstName) {
                                                                clearErrors("billingInfo.firstName");
                                                            }
                                                            onChange(e);
                                                            const lastName = getValues(`billingInfo.lastName`);
                                                            const newNameOnCard = `${e.target.value} ${lastName ?? ""}`;
                                                            setValue("firstName", e.target.value.trim().replace(/\s+/g, " "), { shouldTouch: true, shouldDirty: true });
                                                            setValue("billingInfo.nameOnCard", newNameOnCard.trim().replace(/\s+/g, " "), { shouldTouch: true, shouldDirty: true });
                                                            if (errors?.billingInfo?.nameOnCard) {
                                                                clearErrors("billingInfo.nameOnCard");
                                                            }
                                                        },
                                                        maxLength: 255
                                                    }}
                                                />
                                            </FormField>
                                        )}
                                    />
                                    <Controller
                                        name="billingInfo.lastName"
                                        control={control}
                                        render={({ field: { ref, onChange, ...rest } }) => (
                                            <FormField className="min-w-[250px] flex-1" error={errors?.billingInfo?.lastName?.message} errorId="error-family-name">
                                                <InputWithLabel
                                                    variant="floating"
                                                    label={fieldLabels.lastName.label}
                                                    inputRef={ref}
                                                    error={errors?.billingInfo?.lastName?.message}
                                                    inputProps={{
                                                        ...rest,
                                                        autoComplete: "family-name",
                                                        onChange: e => {
                                                            if (errors?.billingInfo?.lastName) {
                                                                clearErrors("billingInfo.lastName");
                                                            }
                                                            onChange(e);
                                                            const firstName = getValues(`billingInfo.firstName`);
                                                            const newNameOnCard = `${firstName ?? ""} ${e.target.value}`;
                                                            setValue("lastName", e.target.value.trim().replace(/\s+/g, " "), { shouldTouch: true, shouldDirty: true });
                                                            setValue("billingInfo.nameOnCard", newNameOnCard.trim().replace(/\s+/g, " "), { shouldTouch: true, shouldDirty: true });
                                                            if (errors?.billingInfo?.nameOnCard) {
                                                                clearErrors("billingInfo.nameOnCard");
                                                            }
                                                        },
                                                        maxLength: 255
                                                    }}
                                                />
                                            </FormField>
                                        )}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="flex flex-col gap-3">
                            <Heading level="h2" className="text-lg font-bold">
                                Payment Info
                            </Heading>
                            <div className="flex grow flex-col">
                                <div data-testid="billing-frequency-section" className={cn(showTransactionFee ? "rounded-xl bg-background-success px-3 pb-1 pt-2" : "")}>
                                    <RadioButtonGroup
                                        key={selectedBillingFrequency}
                                        onValueChange={async value => await handleFrequencyChange(value as "monthly" | "yearly")}
                                        initialValue={selectedBillingFrequency}
                                        aria-labelledby="billing-frequency"
                                        className="bg-background-weakest"
                                        disabled={isQuoteUpdating}
                                        options={[
                                            { label: "Billed Monthly", value: "monthly" },
                                            { label: "Billed Annually", value: "yearly" }
                                        ]}
                                    />
                                    {showTransactionFee && (
                                        <div className="flex flex-row items-center justify-center gap-1 pt-2">
                                            {frequencyToDisplay === "yearly" ? <FontAwesomeIcon icon={faCircleCheck} className="size-5 text-content-primary" /> : <DollarIcon />}
                                            <span className="text-xs font-semibold text-content-primary">
                                                {frequencyToDisplay === "yearly" ? "You're saving $24/yr!" : "Save $24/yr with annual billing."}
                                            </span>
                                        </div>
                                    )}
                                </div>
                                {isStripeCheckoutEnabled && <StripeCheckout />}
                            </div>
                        </div>
                        <AnimatePresence mode="sync">
                            {showCreditCardFields && (
                                <motion.div key="outer" initial={{ height: 0 }} animate={{ height: `auto` }} exit={{ height: 0 }}>
                                    <motion.div key="inner" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.25 } }} exit={{ opacity: 0 }}>
                                        <div className="flex flex-col">
                                            <Heading level="h2" className="mb-3 text-lg font-bold">
                                                Billing Address
                                            </Heading>
                                            <div className={cn("flex flex-col gap-6")}>
                                                <Controller
                                                    name="billingInfo.nameOnCard"
                                                    control={control}
                                                    render={({ field: { ref, ...rest } }) => (
                                                        <FormField className="min-w-[250px]" error={errors?.billingInfo?.nameOnCard?.message} errorId="error-creditCard-nameOnCard">
                                                            <InputWithLabel
                                                                variant="floating"
                                                                label={Strings.PTZ_US.BILLING_NAME}
                                                                inputRef={ref}
                                                                error={errors?.billingInfo?.nameOnCard?.message}
                                                                inputProps={{
                                                                    ...rest,
                                                                    autoComplete: "cc-name",
                                                                    "aria-describedby": errors?.billingInfo?.nameOnCard ? "error-creditCard-nameOnCard" : undefined,
                                                                    maxLength: 101
                                                                }}
                                                            />
                                                        </FormField>
                                                    )}
                                                />
                                                <Controller
                                                    name="billingInfo.address.street1"
                                                    control={control}
                                                    render={({ field: { ref, onChange, ...rest } }) => (
                                                        <FormField
                                                            className="min-w-[250px] flex-1"
                                                            error={errors?.billingInfo?.address?.street1?.message}
                                                            errorId="error-address-street1"
                                                        >
                                                            <InputWithLabel
                                                                variant="floating"
                                                                label={Strings.PTZ_US.ADDRESS_LINE_1}
                                                                inputRef={ref}
                                                                error={errors?.billingInfo?.address?.street1?.message}
                                                                inputProps={{
                                                                    ...rest,
                                                                    onChange: event => {
                                                                        onChange(event);
                                                                    },
                                                                    autoComplete: "address-line1",
                                                                    "aria-describedby": errors?.billingInfo?.address?.street1 ? "error-address-street1" : undefined,
                                                                    maxLength: 255
                                                                }}
                                                            />
                                                        </FormField>
                                                    )}
                                                />

                                                <Controller
                                                    name="billingInfo.address.street2"
                                                    control={control}
                                                    render={({ field: { ref, onChange, ...rest } }) => (
                                                        <FormField
                                                            className="min-w-[250px] flex-1"
                                                            error={errors?.billingInfo?.address?.street2?.message}
                                                            errorId="error-address-street2"
                                                        >
                                                            <InputWithLabel
                                                                variant="floating"
                                                                label={Strings.PTZ_US.ADDRESS_LINE_2}
                                                                inputRef={ref}
                                                                error={errors?.billingInfo?.address?.street2?.message}
                                                                inputProps={{
                                                                    ...rest,
                                                                    onChange: event => {
                                                                        onChange(event);
                                                                    },
                                                                    autoComplete: "address-line2",
                                                                    "aria-describedby": errors?.billingInfo?.address?.street2 ? "error-address-street2" : undefined,
                                                                    maxLength: 255
                                                                }}
                                                            />
                                                        </FormField>
                                                    )}
                                                />
                                                <Controller
                                                    name="billingInfo.address.city"
                                                    control={control}
                                                    render={({ field: { ref, onChange, ...rest } }) => (
                                                        <FormField
                                                            className="min-w-[250px] flex-1"
                                                            error={errors?.billingInfo?.address?.city?.message}
                                                            errorId="error-address-city"
                                                        >
                                                            <InputWithLabel
                                                                variant="floating"
                                                                label="City"
                                                                inputRef={ref}
                                                                error={errors?.billingInfo?.address?.city?.message}
                                                                inputProps={{
                                                                    ...rest,
                                                                    onChange: event => {
                                                                        onChange(event);
                                                                    },
                                                                    autoComplete: "address-level2",
                                                                    "aria-describedby": errors?.billingInfo?.address?.city ? "error-address-city" : undefined,
                                                                    maxLength: 100
                                                                }}
                                                            />
                                                        </FormField>
                                                    )}
                                                />
                                                <div className="flex gap-5 lg:col-span-2 xl:col-span-1">
                                                    <Controller
                                                        name="billingInfo.address.state"
                                                        control={control}
                                                        render={({ field: { onChange, value, ref } }) => (
                                                            <FormField className="flex-1" error={errors?.billingInfo?.address?.state?.message} errorId="error-address-state">
                                                                <SelectWithLabel
                                                                    ref={ref}
                                                                    id="billing-address-state"
                                                                    labelId="billing-address-state-label"
                                                                    label="State"
                                                                    value={value || ""}
                                                                    options={US_STATES}
                                                                    onValueChange={val => {
                                                                        onChange(val);
                                                                        setValue("billingInfo.address.zipCode", "", { shouldDirty: true });
                                                                    }}
                                                                    placeholder="Select a state"
                                                                    constrainWidthToTrigger={true}
                                                                    variant="floating"
                                                                    triggerProps={{
                                                                        "aria-describedby": errors?.billingInfo?.address?.state ? "error-address-state" : undefined
                                                                    }}
                                                                />
                                                            </FormField>
                                                        )}
                                                    />

                                                    <Controller
                                                        name="billingInfo.address.zipCode"
                                                        control={control}
                                                        render={({ field: { ref, value, onChange, ...rest } }) => (
                                                            <FormField className="flex-1" error={errors?.billingInfo?.address?.zipCode?.message} errorId="error-address-zipCode">
                                                                <InputWithLabel
                                                                    variant="floating"
                                                                    label="Zip Code"
                                                                    inputRef={ref}
                                                                    error={errors?.billingInfo?.address?.zipCode?.message}
                                                                    inputProps={{
                                                                        ...rest,
                                                                        placeholder: fieldLabels.zipCode.placeholder,
                                                                        autoComplete: "postal-code",
                                                                        value: UIUtils.maskPostalCodeUS(value),
                                                                        onChange: e => onChange(UIUtils.maskPostalCodeUS(e.target.value)),
                                                                        "aria-describedby": errors?.billingInfo?.address?.zipCode ? "error-address-zipCode" : undefined
                                                                    }}
                                                                />
                                                            </FormField>
                                                        )}
                                                    />
                                                </div>
                                                <Controller
                                                    name="phone"
                                                    control={control}
                                                    render={({ field: { ref, value, onChange, ...rest } }) => (
                                                        <FormField error={errors?.phone?.message} errorId="error-phone">
                                                            <InputWithLabel
                                                                variant="floating"
                                                                label={Strings.MOBILE_PHONE}
                                                                inputRef={ref}
                                                                error={errors?.phone?.message}
                                                                inputProps={{
                                                                    ...rest,
                                                                    autoComplete: "tel-national",
                                                                    placeholder: fieldLabels.phone.placeholder,
                                                                    type: "tel",
                                                                    value: UIUtils.maskPhone(value),
                                                                    onChange: e => onChange(UIUtils.maskPhone(e.target.value)),
                                                                    "aria-describedby": errors?.phone ? "error-phone" : undefined
                                                                }}
                                                            />
                                                        </FormField>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </motion.div>
                                </motion.div>
                            )}
                        </AnimatePresence>
                        <Terms
                            wrapperClass={cn("max-w-[580px] hidden md:flex mt-5")}
                            handleExtraChange={handleExtraChange}
                            quoteExtra={quote.extra}
                            isQuoteUpdating={isQuoteUpdating}
                            priorityCode={priorityCode}
                            checkboxPlaywrightId="terms-consent-desktop"
                        />
                    </div>
                    <PolicySummary quote={quote} breeds={breeds} wrapperClass="hidden md:flex md:self-start" config={{ isSingleCol: true }} />
                </div>
                <Terms
                    wrapperClass="max-w-[580px] md:hidden"
                    handleExtraChange={handleExtraChange}
                    quoteExtra={quote.extra}
                    isQuoteUpdating={isQuoteUpdating}
                    checkboxPlaywrightId="terms-consent-mobile"
                />
            </div>
        </>
    );
}

interface BlockquoteProps {
    topText?: React.ReactNode;
    bottomText?: React.ReactNode;
    children?: React.ReactNode;
}
export default function Blockquote(props: BlockquoteProps) {
    const { topText, bottomText, children } = props;
    return (
        <blockquote className="border-l-4 border-stroke-primary py-2 pl-6">
            {topText || bottomText ? (
                <div className="flex flex-col gap-1">
                    {topText && <span className="text-lg font-bold text-content-brand sm:text-2xl md:text-3xl">{topText}</span>}
                    {bottomText && <span className="text-xs font-normal sm:text-sm md:text-base">{bottomText}</span>}
                </div>
            ) : (
                children
            )}
        </blockquote>
    );
}

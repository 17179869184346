"use client";
import { Button } from "@/shared/components/ui/Button";
import { useFormMethodsContext } from "@/shared/components/Form";
import Strings from "@/shared/utils/Strings.constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import LoadingSpinner from "@/shared/components/LoadingSpinner";

export default function PolicyCTA() {
    const { onFormContinue, isValidating, canContinue } = useFormMethodsContext();

    return (
        <div className="flex justify-center">
            <Button
                onClick={async () => await onFormContinue()}
                endDecorator={isValidating || !canContinue ? <LoadingSpinner size="sm" /> : <FontAwesomeIcon icon={faChevronRight} />}
                disabled={isValidating || !canContinue}
                type="button"
                className="px-4 py-3"
            >
                {Strings.PTZ_US.CHECK_PRICING}
            </Button>
        </div>
    );
}

import Blockquote from "@/shared/components/ui/blockquote";

export default function PolicyCallout() {
    return (
        <div className="flex flex-col gap-3 sm:gap-5 md:gap-10">
            <div>
                <h1 className="text-base font-bold text-content-brand-b md:text-lg">America&apos;s Favorite Pet Insurance</h1>
                <h2 className="h1 my-1 text-2xl leading-8 sm:my-2 sm:text-3xl sm:leading-9 md:mb-4 md:mt-1 md:text-5xl md:font-extrabold md:leading-[56px]">
                    Worried about unexpected vet bills? We’ve got you covered.
                </h2>
                <p className="text-sm font-medium text-content-primary sm:text-base md:text-xl">
                    Every 6 seconds, a pet owner is faced with a vet bill of $1,000 or more.<sup className="text-xs">1</sup> Spot plans can reimburse up to 90% of covered vet
                    bills.
                </p>
            </div>
            <div className="flex flex-col gap-3 sm:gap-5 md:gap-6">
                <Blockquote
                    topText="$1,000+"
                    bottomText={
                        <>
                            of discounts from special perks<sup>2</sup>
                        </>
                    }
                />
                <Blockquote
                    topText="30-day"
                    bottomText={
                        <>
                            money back guarantee<sup>3</sup>
                        </>
                    }
                />
                <Blockquote
                    topText="$164M"
                    bottomText={
                        <>
                            in claims paid out<sup>4</sup>
                        </>
                    }
                />
            </div>
        </div>
    );
}

// packages
import React from "react";
import Link from "next/link";
import { QueryClient } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import isEqual from "react-fast-compare";
import { DateTime } from "luxon";
import Image from "next/image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/pro-regular-svg-icons";
import { BuilderContent } from "@builder.io/sdk-react";

// components
import SiteHeader from "@/shared/components/SiteHeader";
import { PolicyEditor } from "@/quote-retarget-us/src/components/PolicyEditor";
import { UserContactEditor } from "@/shared/components/UserContactEditor";
import { CoverageEditor } from "@/shared/components/CoverageEditor";
import { BillingEditor } from "@/quote-ptz-us/src/components/BillingEditor";
import SiteFooter from "@/shared/components/SiteFooter";
import FooterContent from "./src/components/FooterContent";
import GiftCardDisclaimer from "@/quote-ptz-us/src/components/GiftCardDisclaimer";
import { CustomizationSlot } from "@/shared/components/CustomizationSlot";
import Exclusions from "@/quote-ptz-us/src/components/Exclusions";
import { PriceInfo } from "@/shared/components/PriceInfo";
import { BillingOutage } from "@/shared/components/BillingOutage";
import modalContent from "@/quote-ptz-us/src/components/FullCoverageDetailsModal";
import { ContactDisclaimer } from "@/quote-ptz-us/src/components/ContactDisclaimer";
import PolicyCTA from "@/quote-retarget-us/src/components/PolicyCTA";
import PolicyCallout from "@/quote-retarget-us/src/components/PolicyCallout";

// context
import { AppStateInterface } from "@/shared/contexts/AppLayer";
import { StripeProvider } from "@/shared/contexts/StripeProvider";

// config
import { PublicConfig } from "@/shared/PublicConfig";

// utils
import { CoverageUtils } from "@/shared/utils/CoverageUtils";
import { PtzUsDataUtils } from "@/quote-ptz-us/src/utils/PtzUsDataUtils";
import { QuoteDataUtils } from "@/shared/utils/QuoteDataUtils";
import { QuoteAPI } from "@/shared/utils/QuoteAPI";
import Strings from "@/shared/utils/Strings.constants";
import { PresetCoverageLevel } from "@/shared/utils/CoverageUtils";
import { TrackingUtils } from "@/shared/utils/TrackingUtils";
import { TOP_BREEDS, US_FOOTER_LINKS } from "@/shared/utils/constants";
import { UIUtils } from "@/shared/utils/UIUtils";
import { BuilderUtils } from "@/shared/utils/BuilderUtils";
import { AnalyticsUtils } from "@/shared/utils/AnalyticsUtils";
import ErrorUtils from "@/shared/utils/ErrorUtils";
import { CookieUtils } from "@/shared/utils/CookieUtils";
import { cn } from "@/shared/utils";
import { EmailUtils } from "@/shared/utils/EmailUtils";
import { SAMPLE_POLICY_URL } from "./src/utils/constants";

// media
import { SpotLogo } from "@/shared/media/SpotLogo";
import CatAndDog from "@/shared/media/images/cat-and-dog.png";

// types
import { FormConfig, FormStep } from "@/shared/types/Form";
import { UsPetPoliciesSchema, UsPolicyStepSchema } from "@/quote-ptz-us/src/schema/PtzUsQuote";
import { PartialQuoteSchema, BillingStepSchema, QuoteFinalized, PolicySchema, Quote, CoverageSettings } from "@/shared/types/Quote.interface";
import { PetQuote, PetUnderwriterType } from "spot-types/entities/PetQuote";
import { SpotErrorMessage, ErrorIdType, PRIORITY_CODE_DEPRECATED } from "@/shared/types/SpotAPI";
import { SubmitHandler, UseFormSetValue } from "react-hook-form";
import { StepperConfig } from "@/shared/components/Stepper";
import { UsQuoteFormStepIds, UsQuoteFormSteps } from "./formConfigTypes";

type UsQuoteFormProps = {
    underwriter: PetUnderwriterType;
    updateAppState: (state: Partial<AppStateInterface>) => void;
    updateQuote?: UseFormSetValue<Quote>;
    isUpdating: boolean;
    queryClient: QueryClient;
    quoteId?: string;
    setQuoteId: (quoteId: string) => void;
    onSubmit: SubmitHandler<QuoteFinalized>;
    currentStep: UsQuoteFormStepIds;
    priorityCode?: string;
    updateCurrentStep: (stepId: UsQuoteFormStepIds, quoteId?: string) => void;
    isApplyAllHidden?: boolean;
    showBillingSubmitButton?: boolean;
    devIsEmailRegisterCheckEnabled?: boolean;
    showVideoTestimonials?: boolean;
    showPolicyDisclaimerBelowCTA?: boolean;
    mockAPIError?: ErrorIdType;
    isMarqueeVariant?: boolean;
};

const FORM_ID = PublicConfig.PTZ_US.FORM_ID;
const ACTIVE_OUTAGES = PublicConfig.PTZ_US.ACTIVE_OUTAGES;
const COOKIE_ID = PublicConfig.PTZ_US.COOKIE_QUOTE_ID_KEY;

const DEFAULT_COVERAGE_SETTINGS: CoverageSettings = {
    coverages: [{ type: "accident" }, { type: "illness" }],
    amounts: {
        reimbursementRate: PublicConfig.PTZ_US.DEFAULT_REIMBURSMENT_RATE,
        annualDeductible: PublicConfig.PTZ_US.DEFAULT_ANNUAL_DEDUCTIBLE,
        annualLimit: PublicConfig.PTZ_US.DEFAULT_ANNUAL_LIMIT
    }
};

export const getUsQuoteForm = ({
    underwriter,
    updateAppState,
    isUpdating,
    queryClient,
    quoteId,
    setQuoteId,
    onSubmit,
    currentStep,
    priorityCode,
    updateCurrentStep,
    updateQuote,
    showBillingSubmitButton,
    devIsEmailRegisterCheckEnabled,
    showVideoTestimonials,
    mockAPIError,
    isMarqueeVariant
}: UsQuoteFormProps): FormConfig<UsQuoteFormStepIds, Quote> => {
    const quoteApi = new QuoteAPI(underwriter);
    // Builder
    const builderUtils = new BuilderUtils(underwriter);
    const initialBuilderContent = queryClient.getQueryData(["builder-ssr-content", "retargeting-form-copy"]) as BuilderContent | undefined;
    const existingCookieQuoteId = CookieUtils.get(COOKIE_ID);

    const emailUtils = new EmailUtils(underwriter, queryClient);

    const petStepFieldLabels = PtzUsDataUtils.getPetStepFieldLabels("floating");

    // Todo: move this to custom hook
    // Temporary change to reuse the update quote logic for retrying the quote update
    const updatePolicyQuote = async ({ quote, isNewQuote, params }: { quote: PetQuote; isNewQuote?: boolean; params?: { [key: string]: string } }) => {
        let queryParams = { ...params };
        if (PublicConfig.ENVIRONMENT === `development` && mockAPIError) {
            queryParams = { sim: `error_id,${mockAPIError}` };
            // Remove sim param if passed in as an argument
            if (params?.hasOwnProperty("sim")) {
                delete queryParams.sim;
            }
        }

        const updatedPetQuote = await quoteApi.updateQuote(quote, queryParams);

        if (!updatedPetQuote?.quoteId) {
            throw new Error("No quote ID in response");
        }
        const updatedQuote = QuoteDataUtils.petQuoteToQuote(updatedPetQuote, underwriter);
        queryClient.setQueryData<Quote>(["quote", updatedPetQuote.quoteId], updatedQuote);

        setQuoteId(updatedPetQuote.quoteId);

        if (isNewQuote) {
            const quoteProperties = TrackingUtils.buildTrackingPayload(updatedPetQuote);
            AnalyticsUtils?.trackSegmentEvent(`Checkout Started`, quoteProperties);

            const builderSessionId = updatedPetQuote?.extra?.queryParams?.builderSessionId;
            const builderVisitorId = updatedPetQuote?.extra?.queryParams?.builderVisitorId;
            if (!!builderSessionId && !!builderVisitorId) {
                try {
                    await TrackingUtils.trackBuilderEvent({ eventName: "Checkout Started", orderId: updatedPetQuote.quoteId, builderSessionId, builderVisitorId });
                } catch (error) {
                    console.error("Error tracking event", error);
                }
            }
        }

        updateAppState({ hasUnknownError: false, isQuoteUpdating: false });
        if (existingCookieQuoteId !== updatedPetQuote.quoteId) {
            CookieUtils.set(COOKIE_ID, updatedPetQuote.quoteId, { expires: 365 });
        }
        return updatedQuote;
    };

    const retryOnPcodeError = async ({ quote, asyncErrors }: { quote: Quote; asyncErrors: SpotErrorMessage[] }) => {
        const hasPcodeError = asyncErrors.some(error => error.id === PRIORITY_CODE_DEPRECATED);
        if (hasPcodeError) {
            const retryData = QuoteDataUtils.quoteToPetQuote({
                ...quote,
                lastStepID: "coverage",
                affiliateCode: "SPOT",
                discountCode: "SPOT"
            });
            const extraQueryParams = retryData?.extra?.queryParams;
            const pcodeValue = UIUtils.getCaseInsensitiveValue(extraQueryParams, "pcode");
            // Get the pcode key programmatically from the value in case it's not "pcode"
            const pcodeKey = extraQueryParams ? Object.entries(extraQueryParams).find(([k, v]) => v === pcodeValue)?.[0] : "";

            if (retryData?.extra?.queryParams && pcodeKey?.length) {
                try {
                    const updatedExtraQueryParams = { ...extraQueryParams, [pcodeKey]: "SPOT" };
                    retryData.extra.queryParams = updatedExtraQueryParams;

                    let params = {};
                    if (PublicConfig.ENVIRONMENT === `development` && mockAPIError) {
                        params = { sim: "" };
                    }

                    return await updatePolicyQuote({ quote: retryData, params: params });
                } catch (error) {
                    updateAppState({ asyncErrors, isQuoteUpdating: false });
                    return false;
                }
            }
        }
    };

    const stepsConfig: FormStep<Quote, UsQuoteFormStepIds>[] = [
        {
            id: UsQuoteFormSteps.PETS.id,
            hideStepper: true,
            hideContinueButton: true,
            hideCtaRow: true,
            continueButtonTitle: Strings.PTZ_US.SELECT_COVERAGE_TEXT,
            stepSchema: UsPolicyStepSchema,
            getInitialValues: quote => PtzUsDataUtils.getPolicyStepInitialValues(quote),
            disclaimerContent: props => {
                return (
                    <>
                        <CustomizationSlot
                            type="above-disclaimer"
                            data={props.value}
                            formId={FORM_ID}
                            formStepId={UsQuoteFormSteps.PETS.id}
                            formData={props.value?.extra?.formData}
                            updateData={data => builderUtils.updateQuoteExtraData({ quote: props.value, newDataArray: data, updateQuote: updateQuote })}
                            priorityCode={priorityCode}
                        />
                        <div className="flex flex-col gap-4 text-xs text-content-secondary">
                            <ContactDisclaimer ctaText={Strings.PTZ_US.CHECK_PRICING} />
                            <GiftCardDisclaimer alignLeft />
                        </div>
                    </>
                );
            },
            allowSubmit: () => !isUpdating,
            allowContinue: async values => {
                if (!values) return false;
                updateAppState({ asyncErrors: undefined, isQuoteUpdating: true });
                const isNewQuote = !values?.id;
                const currentQuote = queryClient.getQueryData(["quote", values.id]) as Quote | undefined;
                const hasQuoteChanged = !isEqual(currentQuote, values);

                // Determine if we need to run email checks
                const emailInQuote = currentQuote?.email;
                const shouldCheckEmail = !values?.id || (!!values.email && emailInQuote !== values.email);

                if (shouldCheckEmail && !!values.email) {
                    const tasks: Promise<boolean>[] = [];

                    if (PublicConfig.ENVIRONMENT === "production" || devIsEmailRegisterCheckEnabled) {
                        tasks.push(
                            emailUtils
                                .verifyEmailRegistration(values.email)
                                .then(emailIsRegistered => {
                                    if (emailIsRegistered) {
                                        updateAppState({ asyncErrors: [{ id: "account-exists" }], isQuoteUpdating: false });
                                        return false;
                                    }
                                    return true;
                                })
                                .catch(error => {
                                    console.error("Error verifying email", error);
                                    return true;
                                })
                        );
                    }

                    tasks.push(
                        emailUtils.handleEmailDebounce({
                            email: values.email,
                            debounce: values?.extra?.debounce,
                            updateAppState
                        })
                    );

                    const results = await Promise.all(tasks);

                    if (results.some(result => result === false)) {
                        return false;
                    }
                }

                // If this is a brand new quote or if something has changed on the policy step form, we need to update the quote
                if (isNewQuote || hasQuoteChanged) {
                    try {
                        const policyData = QuoteDataUtils.quoteToPetQuote({ ...values, lastStepID: UsQuoteFormSteps.COVERAGE.id });
                        return await updatePolicyQuote({ quote: policyData, isNewQuote });
                    } catch (error) {
                        if (isAxiosError(error)) {
                            const errorStatus = error?.response?.status ?? 0;
                            if (ErrorUtils.isSpotApiError(error.response?.data)) {
                                const asyncErrors = ErrorUtils.getErrorIds(error?.response?.data);

                                updateAppState({ asyncErrors, isQuoteUpdating: false });

                                const retryResult = (await retryOnPcodeError({ asyncErrors, quote: values })) as Quote | boolean;

                                if (typeof retryResult === "object" && retryResult.id) return true;

                                return false;
                            }
                            // For unhandled errors, throw a generic "unknown" error to be caught by the global error handler (ex: appState.hasUnknownError)
                            if (errorStatus >= 400) {
                                updateAppState({ hasUnknownError: true, isQuoteUpdating: false });
                                return false;
                            }
                        }
                        return false;
                    }
                } else {
                    // Before allowing the user to continue to the coverage editor, we check to make sure all the policies have valid data and if not, we apply the defaults

                    const validationResults = values.policies?.map(policy => PolicySchema.safeParse(policy)) ?? [];
                    const invalidPolicies = validationResults.filter(result => !result.success);

                    if (invalidPolicies.length > 0) {
                        // Some policies failed validation, apply default coverage settings
                        const updatedPolicies = values.policies?.map((policy, index) => {
                            if (!validationResults[index]?.success) {
                                return {
                                    ...policy,
                                    coverageSettings: DEFAULT_COVERAGE_SETTINGS
                                };
                            }
                            return policy;
                        });

                        // Update the quote with the new policies
                        const updatedQuote = {
                            ...values,
                            policies: updatedPolicies
                        };
                        try {
                            const policyData = QuoteDataUtils.quoteToPetQuote({ ...updatedQuote, lastStepID: UsQuoteFormSteps.COVERAGE.id });
                            return await updatePolicyQuote({ quote: policyData, isNewQuote });
                        } catch (error) {
                            console.error("Error updating quote with default coverage settings:", error);
                            updateAppState({ hasUnknownError: true, isQuoteUpdating: false });
                            return false;
                        }
                    }

                    // If all policies in quote are valid and have pricing, we can update the lastStepID via the scratchpad
                    updateAppState({ hasUnknownError: false, isQuoteUpdating: false });
                    if (!values.lastStepID && !!values.id && !!updateQuote) {
                        await quoteApi
                            .setScratchPadValue(values.id, "extra", { quote: { lastStepID: UsQuoteFormSteps.COVERAGE.id, lastStepIDUpdatedAt: DateTime.utc().toISO() } })
                            .then(() => queryClient.invalidateQueries({ queryKey: ["quote"] }));
                        updateQuote("lastStepID", UsQuoteFormSteps.COVERAGE.id);
                        if (existingCookieQuoteId !== values.id) {
                            CookieUtils.set(COOKIE_ID, values.id, { expires: 365 });
                        }
                    }
                    return values;
                }
            },
            render: props => {
                return (
                    <>
                        <div className="grid grid-cols-1 pt-8 lg:grid-cols-2 lg:gap-8 lg:pt-[150px]">
                            <CustomizationSlot
                                type="retargeting-form-copy"
                                fallback={<PolicyCallout />}
                                data={props.value}
                                formId={FORM_ID}
                                formStepId={UsQuoteFormSteps.PETS.id}
                                formData={props.value?.extra?.formData}
                                updateData={data => builderUtils.updateQuoteExtraData({ quote: props.value, newDataArray: data, updateQuote: updateQuote })}
                                priorityCode={priorityCode}
                                initialContent={initialBuilderContent}
                            />
                            <div className="relative mt-14 sm:mt-8 lg:mt-0">
                                <Image
                                    src={CatAndDog}
                                    alt=""
                                    className="absolute right-4 top-[-94px] w-[160px] sm:top-[-155px] sm:w-[264px] lg:left-1/2 lg:top-[-122px] lg:w-[210px] lg:-translate-x-1/2"
                                />
                                <div className="rounded-3xl bg-background-primary p-3 shadow-md sm:p-8">
                                    <div className="mb-4 grid grid-cols-1 gap-1 sm:grid-cols-2">
                                        <h3 className="h4 flex items-center justify-center text-lg font-bold sm:justify-start">{Strings.TELL_US_PET}</h3>
                                        <div className="flex items-center justify-center gap-1 text-xs sm:justify-end">
                                            <FontAwesomeIcon icon={faHeart} className="text-content-brand" />
                                            {!!quoteId ? Strings.ADD_EDIT_PETS_LATER : Strings.ADD_MULTIPLE_PETS_LATER}
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-6">
                                        <PolicyEditor firstPetOnly {...props} />
                                        <UserContactEditor
                                            config={{
                                                underwriter: "ptz-us",
                                                showMobilePhone: true,
                                                errors: {
                                                    "invalid-postal-code": Strings.ERRORS.ZIP_INVALID
                                                },
                                                zipField: {
                                                    label: Strings.ZIP_CODE,
                                                    mask: UIUtils.maskPostalCodeUS,
                                                    showGiftCardWrapper: true,
                                                    placeholder: " "
                                                },
                                                emailField: {
                                                    label: Strings.EMAIL_ADDRESS,
                                                    placeholder: " "
                                                },
                                                firstNameField: {
                                                    label: Strings.FIRST_NAME,
                                                    placeholder: " "
                                                },
                                                lastNameField: {
                                                    label: Strings.LAST_NAME,
                                                    placeholder: " "
                                                },
                                                phoneField: {
                                                    label: Strings.MOBILE_PHONE_OPTIONAL,
                                                    placeholder: " "
                                                }
                                            }}
                                            styles={{
                                                wrapper: "grid grid-cols-2 gap-6",
                                                fields: {
                                                    zipCode: "order-1 col-span-2",
                                                    email: "order-2 col-span-2",
                                                    firstName: "order-3 col-span-1",
                                                    lastName: "order-4 col-span-1",
                                                    phone: "order-5 col-span-2"
                                                }
                                            }}
                                            {...props}
                                        />
                                        <PolicyCTA />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <CustomizationSlot
                            type="above-cta"
                            data={props.value}
                            formId={FORM_ID}
                            formStepId={UsQuoteFormSteps.PETS.id}
                            formData={props.value?.extra?.formData}
                            updateData={data => builderUtils.updateQuoteExtraData({ quote: props.value, newDataArray: data, updateQuote: updateQuote })}
                            priorityCode={priorityCode}
                        />
                    </>
                );
            }
        },
        {
            id: UsQuoteFormSteps.COVERAGE.id,
            isMaxDeterministicStep: true,
            bottomWidget: props => {
                const { roundedPrice, discountsAmount } = CoverageUtils.getPriceInfoData({ value: props?.value, includeTransactionFee: false });
                return <PriceInfo totalPrice={roundedPrice} policiesCount={props?.value?.policies?.length ?? 0} discountsAmount={discountsAmount} variant="inline" />;
            },
            hideBackButton: false,
            continueButtonTitle: Strings.PROCEED_TO_CHECKOUT,
            disclaimerContent: props => {
                return (
                    <React.Fragment>
                        <CustomizationSlot
                            type="above-disclaimer"
                            data={props.value}
                            formId={FORM_ID}
                            formStepId={UsQuoteFormSteps.COVERAGE.id}
                            formData={props.value?.extra?.formData}
                            updateData={data => builderUtils.updateQuoteExtraData({ quote: props.value, newDataArray: data, updateQuote: updateQuote })}
                            priorityCode={priorityCode}
                        />
                        <GiftCardDisclaimer />
                    </React.Fragment>
                );
            },
            render: props => {
                const coveragePresetData: PresetCoverageLevel[] = [
                    {
                        name: "Basic",
                        config: {
                            type: ["accident", "illness"],
                            deductible: PublicConfig.PTZ_US.DEFAULT_ANNUAL_DEDUCTIBLE,
                            reimbursementPercent: PublicConfig.PTZ_US.DEFAULT_REIMBURSMENT_RATE,
                            annualLimit: PublicConfig.PTZ_US.DEFAULT_ANNUAL_LIMIT
                        }
                    }
                ];

                const discountCode = props.value?.discountCode?.toLowerCase();

                const showTakeoverProvision = PublicConfig.TAKEOVER_PROVISION_PCODES.includes(discountCode) || (discountCode && discountCode.includes("ebtp"));

                return (
                    <>
                        <CoverageEditor
                            {...props}
                            editorConfig={{
                                formId: FORM_ID,
                                title: "Create your plan",
                                includeTransactionFee: false,
                                coveragePresetData,
                                termsInModal: ["annualDeductible", "annualLimit", "reimbursement"],
                                samplePolicyUrl: SAMPLE_POLICY_URL,
                                modalContent: modalContent,
                                preventiveConfig: {
                                    labels: {
                                        basic: Strings.PTZ_US.PREVENTIVE_BASIC,
                                        advanced: Strings.PTZ_US.PREVENTIVE_ADVANCED
                                    }
                                },
                                exclusions: <Exclusions />,
                                customizationSlot: (
                                    <CustomizationSlot
                                        type="above-cta"
                                        data={props.value}
                                        formId={FORM_ID}
                                        formStepId={UsQuoteFormSteps.COVERAGE.id}
                                        formData={props.value?.extra?.formData}
                                        updateData={data => builderUtils.updateQuoteExtraData({ quote: props.value, newDataArray: data, updateQuote: updateQuote })}
                                        priorityCode={priorityCode}
                                    />
                                ),
                                petModalConfig: {
                                    resolverSchema: UsPetPoliciesSchema,
                                    topBreeds: TOP_BREEDS,
                                    showMicrochip: false,
                                    formStyles: {
                                        wrapper: cn("grid grid-cols-2 gap-y-6 gap-x-4"),
                                        fields: {
                                            name: "order-1 col-span-2",
                                            age: "order-2 col-span-2",
                                            species: "order-3 col-span-1",
                                            gender: "order-4 col-span-1",
                                            breed: "order-5 col-span-2"
                                        }
                                    },
                                    ageField: { constrainSelectWidthToTrigger: true, label: petStepFieldLabels.age.label, placeholder: petStepFieldLabels.age.placeholder },
                                    nameField: { label: petStepFieldLabels.name.label },
                                    breedField: { label: petStepFieldLabels.breed.label, placeholder: petStepFieldLabels.breed.placeholder }
                                },
                                getModalInitialValues: QuoteDataUtils.createNewPolicyUS,
                                showTakeoverProvision: showTakeoverProvision,
                                priorityCode: priorityCode,
                                multiPetVariant: "card"
                            }}
                        />
                    </>
                );
            },
            hidePreviousSteps: true,
            allowSubmit: values => {
                if (!values) return false;
                if (isUpdating) return false;

                let isValid = true;

                values?.policies?.forEach(policy => {
                    if (!policy) {
                        isValid = false;
                        return;
                    }

                    // Using safeParse for validation
                    const validationResult = PolicySchema.safeParse(policy);

                    if (!validationResult.success) {
                        isValid = false;
                    }
                });

                // Return false if any policy was invalid, true otherwise
                return isValid;
            },
            allowContinue: async values => {
                if (!values) return Promise.resolve(false);
                updateAppState({ asyncErrors: undefined, isQuoteUpdating: true });
                const currentQuote = queryClient.getQueryData(["quote", values.id]) as Quote | undefined;
                const hasQuoteChanged = !isEqual(currentQuote, values);

                if (hasQuoteChanged) {
                    try {
                        const policyData = QuoteDataUtils.quoteToPetQuote({
                            ...values,
                            lastStepID: ACTIVE_OUTAGES.includes("ptz-us-billing-api") ? UsQuoteFormSteps["BILLING-OUTAGE"].id : UsQuoteFormSteps.BILLING.id
                        });
                        return await updatePolicyQuote({ quote: policyData });
                    } catch (error) {
                        if (isAxiosError(error)) {
                            const errorStatus = error?.response?.status ?? 0;

                            if (ErrorUtils.isSpotApiError(error.response?.data)) {
                                const asyncErrors = ErrorUtils.getErrorIds(error?.response?.data);

                                updateAppState({ asyncErrors, isQuoteUpdating: false });

                                const retryResult = (await retryOnPcodeError({ asyncErrors, quote: values })) as Quote | boolean;

                                if (typeof retryResult === "object" && retryResult.id) return true;

                                return false;
                            }

                            // For unhandled errors, throw a generic "unknown" error to be caught by the global error handler (ex: appState.hasUnknownError)
                            if (errorStatus >= 400) {
                                updateAppState({ hasUnknownError: true, isQuoteUpdating: false });
                                return false;
                            }
                        }
                        return false;
                    }
                } else {
                    updateAppState({ hasUnknownError: false, isQuoteUpdating: false });
                    if (!!values.id && !!updateQuote) {
                        const newStepID = ACTIVE_OUTAGES.includes("ptz-us-billing-api") ? UsQuoteFormSteps["BILLING-OUTAGE"].id : UsQuoteFormSteps.BILLING.id;
                        await quoteApi
                            .setScratchPadValue(values.id, "extra", { quote: { lastStepID: newStepID, lastStepIDUpdatedAt: DateTime.utc().toISO() } })
                            .then(() => queryClient.invalidateQueries({ queryKey: ["quote"] }));
                        updateQuote("lastStepID", newStepID);
                    }
                    return true;
                }
            }
        },
        {
            id: "billing-outage",
            hideContinueButton: true,
            hidePreviousSteps: true,
            shouldSkip: !ACTIVE_OUTAGES.includes("ptz-us-billing-api"),
            render: props => <BillingOutage {...props} underwriter={underwriter} />
        },
        {
            id: UsQuoteFormSteps.BILLING.id,
            hideContinueButton: !showBillingSubmitButton,
            stepSchema: BillingStepSchema,
            getInitialValues: quote => QuoteDataUtils.getBillingStepInitialValues(quote),
            continueButtonTitle: Strings.COMPLETE_CHECKOUT,
            bottomWidget: props => {
                const { roundedPrice, discountsAmount } = CoverageUtils.getPriceInfoData({ value: props?.value, includeTransactionFee: true });
                return <PriceInfo totalPrice={roundedPrice} policiesCount={props?.value?.policies?.length ?? 0} discountsAmount={discountsAmount} variant="inline" />;
            },
            allowSubmit: () => !isUpdating,
            allowContinue: async values => {
                if (!values) return false;
                if (!values.phone) return false;
                updateAppState({ asyncErrors: undefined, isQuoteUpdating: true, showFinalLoader: true });
                try {
                    const extraWithThankYou = {
                        ...values.extra,
                        thankYouURL: `${window.location.origin}${PublicConfig.BASE_PATH}/forms/${FORM_ID}/thankyou?uw=${underwriter}&quoteId=${values.id}`
                    };
                    await onSubmit({ ...values, extra: extraWithThankYou } as QuoteFinalized);
                    updateAppState({ isQuoteUpdating: false });
                    return true;
                } catch (error) {
                    updateAppState({ showFinalLoader: false, isQuoteUpdating: false });
                    if (error instanceof Error && !!error?.message && error?.message.toLowerCase().includes("billing zipcode")) {
                        const asyncErrors = [{ id: `invalid-postal-code`, at: "zipcode" }] as SpotErrorMessage[];
                        updateAppState({ asyncErrors });
                    }
                    console.error(error);
                    return false;
                }
            },
            render: props => {
                const { roundedPrice } = CoverageUtils.getPriceInfoData({ value: props.value, includeTransactionFee: true });
                return (
                    <React.Fragment>
                        <StripeProvider amount={roundedPrice}>
                            <BillingEditor {...props} />
                        </StripeProvider>
                        <CustomizationSlot
                            type="above-cta"
                            data={props.value}
                            formId={FORM_ID}
                            formStepId={UsQuoteFormSteps.BILLING.id}
                            formData={props.value?.extra?.formData}
                            updateData={data => builderUtils.updateQuoteExtraData({ quote: props.value, newDataArray: data, updateQuote: updateQuote })}
                            priorityCode={priorityCode}
                        />
                    </React.Fragment>
                );
            },
            disclaimerContent: props => {
                return (
                    <React.Fragment>
                        <CustomizationSlot
                            type="above-disclaimer"
                            data={props.value}
                            formId={FORM_ID}
                            formStepId={UsQuoteFormSteps.BILLING.id}
                            formData={props.value?.extra?.formData}
                            updateData={data => builderUtils.updateQuoteExtraData({ quote: props.value, newDataArray: data, updateQuote: updateQuote })}
                            priorityCode={priorityCode}
                        />
                        <GiftCardDisclaimer />
                    </React.Fragment>
                );
            },
            hidePreviousSteps: true
        }
    ];

    const stepsForStepper: StepperConfig<UsQuoteFormStepIds>[] = stepsConfig.map(step => ({
        stepValue: step.id as UsQuoteFormStepIds,
        shouldSkip: step.shouldSkip,
        hideStepper: step.hideStepper
    }));

    return {
        id: FORM_ID,
        schema: PartialQuoteSchema,
        customPageClassName: currentStep === UsQuoteFormSteps.PETS.id ? "bg-background-secondary" : "",
        customStepClassName: currentStep === UsQuoteFormSteps.PETS.id ? "pb-8" : "",
        header: (value, otherValues, isUpdating) => {
            return (
                <SiteHeader
                    quote={value}
                    updateQuote={updateQuote}
                    formID={FORM_ID}
                    underwriter={underwriter}
                    phoneNumber={Strings.PTZ_US.PHONE_NUMBER}
                    logo={<SpotLogo />}
                    logoFullWidth={currentStep === UsQuoteFormSteps.PETS.id}
                    currentStep={currentStep}
                    steps={stepsForStepper}
                    updateCurrentStep={updateCurrentStep}
                />
            );
        },
        steps: stepsConfig,
        footer: (value, otherValues, isUpdating) => {
            const extendedDisclaimers = [
                {
                    isVisible: currentStep === UsQuoteFormSteps.PETS.id,
                    disclaimer: (
                        <>
                            <div>
                                <sup>1</sup> &quot;Pet Insurance Statistics 2024&quot;{" "}
                                <a href="https://www.forbes.com/advisor/pet-insurance/pet-insurance-statistics/" target="_blank" className="underline">
                                    Forbes Advisor
                                </a>
                                , Jan 3, 2024.‍‍
                            </div>
                            <div>
                                <sup>2</sup> No purchase necessary. Based on total combined Spot Perks discounts applied to avg. vendor cart value.
                                <a href="https://spotpet.com/perks-terms" target="_blank" className="underline">
                                    spotpet.com/perks-terms‍
                                </a>
                            </div>
                            <div>
                                <sup>3</sup> Available if no claims have been filed. There is a 15-day money back guarantee in ME, LA and WA. Not available in NY.
                            </div>
                            <div>
                                <sup>4</sup> Jan. 2019 to Nov. 2024 administrator claims data. Includes total reimbursed for accident, illness & wellness.‍ Individual reimbursement
                                results and coverage vary based on plan type.
                            </div>
                        </>
                    )
                },
                {
                    isVisible: priorityCode === PublicConfig.PTZ_US.FORBES_QUOTERS_PCODE,
                    disclaimer: (
                        <div className="leading-5">
                            † Comparison information is provided using publicly available information as of 8/28/24 and is only meant to summarize program features, not a specific
                            plan. Review each provider’s plan terms for more details. The descriptions of other providers’ plans were not provided by that company. If you have
                            questions about other plans, please contact an agent of that company. It is our intention to provide fair and accurate comparison information. Although
                            we attempt to keep information up to date, it may change from time to time. If you are aware of any inaccuracies or changes in the information provided,
                            let us know by visiting{" "}
                            <Link className="underline" href={`${PublicConfig.PTZ_US.SPOT_MARKETING_URL}/complaints-and-feedback`}>
                                spotpet.com/complaints-and-feedback
                            </Link>{" "}
                            . Once your deductible is met, exam fees for accidents and illnesses related to covered conditions are covered. Annual physical exam fees are not
                            covered. With Embrace an additional monthly fee is required. Prescription food & supplements are covered if they are prescribed to treat an eligible
                            accident or illness. Prescription food & supplements are not covered if they are used for weight management or general health maintenance.
                        </div>
                    )
                },
                {
                    isVisible: isMarqueeVariant,
                    disclaimer: (
                        <div className="flex flex-col gap-4 leading-5">
                            <span>
                                <sup>1</sup> No purchase necessary. Based on total combined Spot Perks discounts applied to avg. Vendor cart value.{" "}
                                <a href="spotpet.com/perks-terms" target="_blank">
                                    spotpet.com/perks-terms
                                </a>
                            </span>
                            <span>
                                <sup>2</sup> Available if no claims have been filed. There is a 15-day money back guarantee in ME, LA and WA. Not available in NY.
                            </span>
                            <span>
                                <sup>3</sup> 10% multi-pet discount available on all pets after the first.
                            </span>
                        </div>
                    )
                }
            ];

            const disclaimersToShow = extendedDisclaimers.filter(disclaimer => disclaimer.isVisible).map(disclaimer => disclaimer.disclaimer);

            return (
                <React.Fragment>
                    <SiteFooter
                        underwriter={underwriter}
                        formID={FORM_ID}
                        currentStep={currentStep}
                        links={US_FOOTER_LINKS}
                        content={<FooterContent />}
                        extraContent={disclaimersToShow}
                        copyright={Strings.PTZ_US.COPYRIGHT_TEXT}
                        hasOffset={false}
                        priorityCode={priorityCode}
                        showVideoTestimonials={showVideoTestimonials}
                        className={currentStep === UsQuoteFormSteps.PETS.id ? "mt-0" : ""}
                    />
                </React.Fragment>
            );
        }
    };
};

export class PrivateConfig {
    // ===============================
    //          GLOBAL VALUES
    // ===============================
    static DEFAULT_FORM_ID = process.env.DEFAULT_FORM_ID ?? "quote-ptz-us";
    static SPOT_API_EVENTS_TOKEN_AWS =
        process.env.SPOT_API_EVENTS_TOKEN_AWS ??
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1IjoicXVvdGUtZm9ybS12MyIsImkiOlsiL2V2ZW50cyJdLCJpYXQiOjE3MDExMDc4Mzd9.P7K8cSL2t96bTSp34ttdAMkp0t4IRMZYoVqAj7LZmqM";
    static STATSIG_SERVER_KEY = process.env.STATSIG_SERVER_KEY ?? "secret-sK6Rcxaxyh9oVsVukJVlmYCNvpMljj0NjIqGtLcygCR";
    static IS_STATSIG_ENABLED: boolean = process.env.IS_STATSIG_ENABLED?.toLowerCase() === "true" ?? true;
    static BUILDER_SPOT_WEB_API_KEY: string = process.env.BUILDER_SPOT_WEB_API_KEY ?? "e0e4313549ac4efa9fbefb8c6275dc1d";

    // ===============================
    // FORM-SPECIFIC VALUES BELOW HERE
    // ===============================
}

// packages
import { z } from "zod";
import { useFieldArray, useFormContext } from "react-hook-form";

// components
import { PetInfoEditor } from "@/shared/components/PetInfoEditor";

// hooks
import { useModal } from "@/shared/hooks/useModal";
import { useBreeds } from "@/shared/hooks/useBreeds";
import { useFormParentContext } from "@/shared/contexts/FormParent";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";

// utils
import { TOP_BREEDS } from "@/shared/utils/constants";
import Strings from "@/shared/utils/Strings.constants";

// types
import { FormStepProps, OtherProps } from "@/shared/types/Form";
import { Quote } from "@/shared/types/Quote.interface";
import { UsPolicyStepSchema } from "@/quote-ptz-us/src/schema/PtzUsQuote";

type AllPolicyStepProps = z.infer<typeof UsPolicyStepSchema>;
type StepKeys = Extract<keyof Quote, keyof AllPolicyStepProps>;

export function PolicyEditor(props: FormStepProps<Quote, StepKeys, OtherProps | undefined> & { firstPetOnly?: boolean }) {
    const modal = useModal();

    const { appState } = useAppLayerContext();
    const { underwriter, updateQuote } = appState;
    const { breeds } = useBreeds(underwriter);
    const { control, watch } = useFormContext<AllPolicyStepProps>();
    const { watch: watchParent, getValues: getParentValues } = useFormParentContext<Quote>();
    const quoteId = watchParent(`id`);

    const { remove } = useFieldArray({
        control,
        name: "policies"
    });

    const policies = watch("policies");
    const policiesToShow = props.firstPetOnly && policies?.length > 1 ? policies.slice(0, 1) : policies;

    const handleDeletePet = async (index: number) => {
        if (!(await modal.confirm())) {
            return;
        }

        const deletedPolicyId = policies[index]?.id;
        remove(index);

        if (!!quoteId) {
            const parentValues = getParentValues();
            const updatedParentPolicies = parentValues.policies?.filter(p => p.id !== deletedPolicyId) ?? parentValues.policies;
            const updatedParentValues = { ...parentValues, policies: updatedParentPolicies };
            updateQuote?.mutate(updatedParentValues);
        }
    };

    return (
        <div className="flex flex-1 flex-col gap-6 bg-background-primary">
            {policiesToShow.map((field, index) => {
                return (
                    <div key={field?.id ?? `field-${index}`} data-testid="pet-row">
                        <PetInfoEditor
                            petBreeds={breeds}
                            topBreeds={TOP_BREEDS}
                            policyIndex={index}
                            showAge={true}
                            allowDelete={policiesToShow?.length > 1}
                            onDelete={async () => await handleDeletePet(index)}
                            ageField={{
                                label: Strings.AGE,
                                constrainSelectWidthToTrigger: true
                            }}
                            breedField={{
                                label: Strings.BREED,
                                placeholder: Strings.SELECT_BREED
                            }}
                            nameField={{
                                label: Strings.PETS_NAME
                            }}
                            styles={{
                                wrapper: "grid grid-cols-2 gap-6",
                                fields: {
                                    name: "order-1 max-[480px]:col-span-2 col-span-1",
                                    age: "order-2 max-[480px]:col-span-2 col-span-1",
                                    species: "order-3 col-span-1",
                                    gender: "order-4 col-span-1",
                                    breed: "order-5 col-span-2"
                                }
                            }}
                            containBreedDropdown
                        />
                    </div>
                );
            })}

            {modal.render}
        </div>
    );
}
